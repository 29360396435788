import React from "react"
import ArticleElement from "./articleElement"
import styled from "styled-components"

const ArticlesFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 100px -20px;
  flex-wrap: wrap;
  @media screen and (max-width: 1280px) {
    justify-content: center;
  }
`

const ArticlesGrid = ({ posts }) => {
  return (
    <ArticlesFlexContainer>
      {posts.map((post) => {
        const { title, excerpt, path } = post
        const localFile = post.featured_media.localFile
        return (
          <ArticleElement
            key={title}
            style={{ margin: "20px" }}
            title={title}
            excerpt={excerpt}
            localFile={localFile}
            path={path}
          />
        )
      })}
    </ArticlesFlexContainer>
  )
}

export default ArticlesGrid
