import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomepageMenu from "../components/homepageMenu"
import SideTitleBox from "../components/sideTitleBox"
import CTASection from "../components/CTASection"
import ListSection from "../components/listSection"
import ServicesSection from "../components/servicesSection"
import ProjectsSection from "../components/projectsSection"
import FlowMapImage from "../components/flowMapImage"
import { BorderedHeader, Container } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import BorderedHeaderWrapper from "../components/borderedHeaderWrapper"
import ArticlesGrid from "../components/articlesGrid"
import ClientsSlider from "../components/sliders/clientsSlider"
import LastCTASection from "../components/lastCTASection"
import styled from "styled-components"
import { BigMobileFont } from "../components/typography"

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`

const HeroWrapper = styled.div`
  height: calc(100vh - 120px);
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
  }
`

const IndexPage = ({ data }) => {
  const {
    CTASectionButtonText,
    CTASectionButtonUrl,
    CTASectionTitle,
    CTASectionChangingText,
    firstSectionTitle,
    firstSectionContentTitle,
    firstSectionContentText,
    listSectionTitle,
    list,
    servicesSectionTitle,
    services,
    portfolioSectionTitle,
    blogSectionTitle,
    blogButtonToBlog,
    clientsSectionTitle,
    lastCTASectionTitle,
    lastCTASectionText,
    lastCTASectionButtonUrl,
    lastCTASectionButtonText,
    heroImage,
    homepageMenuTitle,
    homepageMenuBottomTitle,
  } = data.wordpressPage.cmb2.metabox_homepage
  const lastThreePosts = data.allWordpressPost.nodes

  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/"
      />
      <Container>
        <HeroWrapper>
          <HomepageMenu
            menuTitle={homepageMenuTitle}
            bottomTitle={homepageMenuBottomTitle}
          />
          <FlowMapImage
            imagePublicURL={heroImage.localFile.publicURL}
            size={heroImage.media_details}
          />
        </HeroWrapper>
      </Container>

      <Container>
        <SideTitleBox
          title={firstSectionTitle}
          subtitle={firstSectionContentTitle}
          text={firstSectionContentText}
        />
      </Container>

      <Container>
        <div style={{ margin: "90px 0 80px" }}>
          <CenterBorderedHeader size="small">
            <BigMobileFont desktopFontSize="2.5rem">
              {clientsSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <ClientsSlider showMultipleClients />
        </div>
      </Container>

      <CTASection
        title={CTASectionTitle}
        changingText={CTASectionChangingText}
        buttonText={CTASectionButtonText}
        buttonUrl={CTASectionButtonUrl}
      />

      <ListSection title={listSectionTitle} items={list} />

      <ServicesSection title={servicesSectionTitle} services={services} />

      <div style={{ margin: "160px 0 0 0" }}>
        <Container>
          <FlexColumn>
            {/* Needed for Projects Section mobile order to work */}
            <BorderedHeaderWrapper side="right">
              <BorderedHeader whiteSpace="wrap" side="right">
                <BigMobileFont desktopFontSize="2.5rem">
                  {portfolioSectionTitle}
                </BigMobileFont>
              </BorderedHeader>
            </BorderedHeaderWrapper>
            <ProjectsSection />
          </FlexColumn>
        </Container>
      </div>

      <div style={{ margin: "0 0 130px 0" }}>
        <Container>
          <CenterBorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">
              {blogSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <ArticlesGrid posts={lastThreePosts} />
          {/* <FlexCenter>
            <BaseButton to="/blog/">{blogButtonToBlog}</BaseButton>
          </FlexCenter> */}
        </Container>
      </div>

      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonUrl={lastCTASectionButtonUrl}
        buttonText={lastCTASectionButtonText}
      />
    </Layout>
  )
}
export const query = graphql`
  query HomepageQuery {
    wordpressPage(template: { eq: "page-templates/page-homepage.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_homepage {
          CTASectionButtonText
          CTASectionButtonUrl
          CTASectionTitle
          CTASectionChangingText
          blogSectionTitle
          blogButtonToBlog
          clientsSectionTitle
          firstSectionContentText
          firstSectionContentTitle
          firstSectionTitle
          homepageMenuTitle
          homepageMenuBottomTitle
          lastCTASectionButtonText
          lastCTASectionButtonUrl
          lastCTASectionText
          lastCTASectionTitle
          portfolioSectionTitle
          servicesSectionTitle
          services {
            buttonText
            buttonUrl
            image {
              source_url
            }
            # technologies
            text
            title
          }
          listSectionTitle
          list {
            buttonText
            buttonUrl
            contentText
            contentTitle
            title
          }
          heroImage {
            localFile {
              publicURL
            }
            media_details {
              width
              height
            }
          }
        }
      }
    }
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        excerpt
        title
        path
        featured_media {
          localFile {
            childImageSharp {
              fixed(height: 215, width: 330) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
