import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { BaseButton } from "./styledComponents"
import { Container } from "./styledComponents"
import FlowMapImage from "./flowMapImage"
import { BigMobileFont } from "./typography"

const Header = styled.h2`
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`

const CTASectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4rem 12vw 4rem 0;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: unset;
    margin: 4rem 0;
    & > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const WingImgContainer = styled.div`
  width: 35vw;
  margin-right: -6vw;
  @media screen and (max-width: 500px) {
    width: 80vw;
  }
`

const CTASection = ({ title, changingText, buttonText, buttonUrl }) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "ctaWings" }) {
          publicURL
        }
      }
    `
  )
  const [currentChangingText, setCurrentChangingText] = useState("")
  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
  }
  useEffect(() => {
    ;(async () => {
      let typing = true
      let currentTextIndex = 0
      let currentText = ""
      while (true) {
        if (!typing) {
          // Delete all characters one by one
          for (let index = 1; index <= currentText.length; index++) {
            setCurrentChangingText(currentText.slice(0, -1 * index))
            await sleep(40)
          }
          await sleep(100)
          currentText = ""
        } else {
          // Type the phrase
          for (
            let index = 1;
            index <= changingText[currentTextIndex].length;
            index++
          ) {
            const newText = changingText[currentTextIndex].slice(0, index)
            setCurrentChangingText(newText)
            await sleep(100)
          }
          await sleep(1500)
          currentText = changingText[currentTextIndex]
          // Stop typing and move onto next phrase
          typing = false
          if (currentTextIndex === changingText.length - 1) {
            currentTextIndex = 0
          } else {
            currentTextIndex++
          }
        }
        if (currentText === "") typing = true
      }
    })()
  }, [changingText])

  return (
    <CTASectionWrapper>
      <WingImgContainer>
        <FlowMapImage
          imagePublicURL={data.file.publicURL}
          size={{ width: 536, height: 621 }}
        />
      </WingImgContainer>
      <Container>
        <Header>
          <BigMobileFont desktopFontSize="3.5rem" desktopLineHeight="4.25rem">
            {title}
            <br />
            {currentChangingText}_
          </BigMobileFont>
        </Header>
        {buttonText && <BaseButton to={buttonUrl}>{buttonText}</BaseButton>}
      </Container>
    </CTASectionWrapper>
  )
}

export default CTASection
